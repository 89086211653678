import React, {Component, Fragment} from 'react'
import './styles/profile.css'
import ArrowBack from "@material-ui/icons/ArrowBack";
import {Paper, Typography} from "@material-ui/core";
import patientJSON from './../assets/data/patientList';
import thumb1 from './../assets/patientThumb/1.jpg';
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Fragment>
                <Paper square='true' id='profileHeader'>
                    <Link to="/">
                        <div style={{color: '#FFFFFF', position: "absolute", margin: 0}}>
                            <ArrowBack/>
                        </div>
                    </Link>
                    <div style={{paddingTop: 50, paddingBottom: 50}}>
                        <Avatar style={{margin: 'auto', width: '40%', height: '40%'}} alt={patient.name} src={thumb1}
                                component='div'/>
                        <div style={{marginTop: 10}}><font color="white" id={'profileName'}>{patient.name}</font></div>
                    </div>
                </Paper>
                <Paper square='true' id='profileData'>
                    <Typography>
                        Gender: {patient.gender}
                    </Typography>
                    <Typography>
                        Age: {patient.age}
                    </Typography>
                    <Typography>
                        Weight: {patient.weight}
                    </Typography>
                    <Typography>
                        Height: {patient.height}
                    </Typography>
                    <Typography>
                        Allergies: {patient.allergies}
                    </Typography>
                    <Typography>
                        Blood Group: {patient.bloodgrp}
                    </Typography>
                </Paper>
            </Fragment>
        );
    }
}

export default Profile;
