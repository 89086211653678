import React, {Component} from 'react'
import './styles/patientcard.css';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import CommentIcon from '@material-ui/icons/Comment';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class CommunityCommentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            liked: props.liked || false,
            likeCount: props.likeCount || 0,
            commentCount: props.commentCount || 0,
            doctorName: props.doctorName || 'Sigmund Freud',
            comment: props.comment || "Nice Article"
        };
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Typography color="textPrimary" gutterBottom>
                        {this.state.doctorName}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        {this.state.comment}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Like</Button>
                    <Button size="small">Reply</Button>
                </CardActions>
            </Card>
        );
    }

    share() {
        this.setState({shareCount: this.state.shareCount + 1})
    }

    like() {
        if (this.state.liked) {
            this.setState({liked: false, likeCount: this.state.likeCount - 1});
        } else {
            this.setState({liked: true, likeCount: this.state.likeCount + 1});
        }
    }
}

export default CommunityCommentCard;
