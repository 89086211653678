import React, {Component} from 'react'
import './styles/data.css'
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import patientJSON from './../assets/data/patientList';
import image from "../assets/data/xray.png";
import "./styles/data.css"
import {Link} from "react-router-dom";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";


class Data extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Grid sm={12} component='div'>
                <Card>
                    <Link to={`/dicom/${this.state.patientId}/1`}>
                    <CardActionArea>
                        <div className="Post-image">
                            <div className="Post-image-bg">
                                <img width='inherit' src={image} alt={patient}/>
                            </div>
                        </div>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                X-ray(Lungs)
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                by Dr. Vinay, Dr.Lal Pathology Labs
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Link>
                    <CardActions>
                        <Link to={`/dicom/${this.state.patientId}/1`}>
                            <Button size="small" color="primary">
                                View in Dicom Viewer
                            </Button>
                        </Link>
                        <Link to={`/community?datum=1&patient=${this.state.patientId}`}>
                            <Button size="small" color="primary">
                                Ask in Community
                            </Button>
                        </Link>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

export default Data;
