import React, {Component} from 'react'
import './styles/patientInfo.css';
import {Data, PatientModal, Profile, TestsCard} from "../components";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";

class PatientInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.match.params.patientId,
            currentModal: false,
            height: window.innerHeight
        };
    }

    closeModal() {
        this.setState({currentModal: false})
    }

    render() {
        return (
            <Grid container component='div'>
                <Grid sm={4} component='div'>
                    <Profile
                        patientId={this.state.patientId}
                    />
                </Grid>
                <Grid xs={8} component='div'>
                    <div style={{height: this.state.height, overflow:'auto', paddingLeft: 10, paddingRight: 10}}>
                        <Data
                            patientId={this.state.patientId}
                        />
                        <TestsCard
                            patientId={this.state.patientId}
                            onClick={() => {
                                this.setState({currentModal: true})
                            }}
                        />
                    </div>
                </Grid>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={!(this.state.currentModal === false)}
                    onClose={this.closeModal.bind(this)}
                >
                    {
                        this.state.currentModal === true ?
                            <PatientModal patientId={this.state.patientId}/>
                            :
                            null
                    }
                </Modal>
            </Grid>
        );
    }
}

export default PatientInfo;
