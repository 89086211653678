import React, {Component} from 'react'
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import './styles/landing.css';
import Avatar from "@material-ui/core/Avatar";
import image2 from "../assets/data/doctor.jpg"
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import doctor from './../assets/data/doctor';
import {Link} from "react-router-dom";
import ArrowBack from "@material-ui/core/SvgIcon/SvgIcon";
import {CommunityCard, CommunityEditCard, CommunityChatCard} from "../components";


class Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
        };
    }

    render() {
        return (
            <div style={{height: 'auto'}}>
                <Paper square={true}
                       style={{
                           textAlign: 'center',
                           backgroundImage: "linear-gradient(#1665c1, #2886ee)",
                           height: 175,
                           maxHeight: '100%',
                           overflow: 'auto'
                       }}>
                    {/* Todo: Make below icon visible */}
                    <Link to="/">
                        <div style={{color: '#FFFFFF', position: "absolute", margin: 0}}>
                            <ArrowBack/>
                        </div>
                    </Link>
                    <Typography
                        align="center"
                        color='#FFFFFF'
                        variant="h3">
                        <font color="#FFFFFF" >
                            Community
                        </font>
                    </Typography>
                    <Paper id="qwerty" style={{backgroundColor: '#FFFFFF'}}>
                        <Grid container style={{height: '100%', padding: '10px'}} justify="center">
                            <Grid style={{margin: 'auto'}} sm={2} component='div'>
                                <Avatar style={{margin: 'auto', width: 80, height: 80}} className="doctorAvatar"
                                        alt={doctor.name} src={image2} component='div'/>
                            </Grid>
                            <Grid sm={4}>
                                <h3 style={{color: 'black'}}>{doctor.name}</h3>
                            </Grid>
                            <Grid sm={6} component='div'>
                                <Box p={2} ml={4} bgcolor="background.paper">

                                    <Typography>
                                        Specialization: {doctor.field}
                                    </Typography>
                                    <Typography>
                                        Hospital: {doctor.hospital}
                                    </Typography>
                                    <Typography>
                                        Session: {doctor.session}
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Paper>
                <Grid style={{marginTop: 100}} container>
                    <Grid sm={1}>
                    </Grid>
                    <Grid sm={6}>
                        <CommunityEditCard
                            doctorName={doctor.name}
                            doctorImage={image2}
                        />
                        <CommunityCard
                            likeCount={132}
                            shareCount={11}
                            commentCount={80}
                        />
                        <CommunityCard
                            likeCount={150}
                            shareCount={15}
                            commentCount={99}
                        />
                        <CommunityCard
                            likeCount={102}
                            shareCount={9}
                            commentCount={50}
                        />
                    </Grid>
                    <Grid sm={1}>
                    </Grid>
                    <Grid sm={3}>
                        <Paper>
                            <CommunityChatCard
                                doctorName="Dr. Rashid"
                                status="Online"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Martin Arrowsmith"
                                status="Online"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Jeremy Stone"
                                status="Online"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Peter Leavitt"
                                status="Online"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Mark Hall"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Charles Burton"
                            />
                            <CommunityChatCard
                                doctorName="Dr. Edward George Armstrong"
                            />
                        </Paper>
                    </Grid>
                    <Grid sm={1}>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Community;
