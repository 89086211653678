import React, {Component} from 'react'
import './styles/patientcard.css';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import CommentIcon from '@material-ui/icons/Comment';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CommunityCommentCard from "./CommunityCommentCard";

class CommunityCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            liked: props.liked || false,
            likeCount: props.likeCount || 0,
            shareCount: props.shareCount || 0,
            commentCount: props.commentCount || 0,
            doctorName: props.doctorName || 'Sigmund Freud',
            content: props.content || "A slowly progressive and ultimately fatal brain disorder that most commonly occurs in children. The infantile form of the disease is characterized by megalencephaly (an abnormally large head), seizures, spasticity and developmental retardation. It leads to death usually within the first decade."
        };
    }

    render() {
        const {expanded} = this.state;
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            {this.state.doctorName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={this.state.doctorName}
                    subheader="September 14, 2016"
                />
                <CardMedia
                    style={{height:300, width:'auto'}}
                    image="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fdxline.info%2Fimg%2Fnew_dictionary%2Falexander-disease.jpg&f=1&nofb=1"
                    title="Paella dish"
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {this.state.content}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container>
                        <Grid sm={4} onClick={()=>{this.like()}}>
                            <Button style={{width:'100%'}} aria-label="add to favorites">
                            {this.state.likeCount}
                            <FavoriteIcon style={{color: this.state.liked ? "red" : ""}}/>
                        </Button>
                        </Grid>
                        <Grid sm={4}  onClick={() => {this.share()}}>
                            <Button style={{width:'100%'}} aria-label="share">
                            {this.state.shareCount}
                            <ShareIcon/>
                        </Button>
                        </Grid>
                        <Grid sm={4} onClick={() => {this.setState({expanded: !expanded})}}>
                            <Button
                                style={{width: '100%'}}
                                // className={clsx(classes.expand, {
                                //     [classes.expandOpen]: expanded,
                                // })}
                                aria-expanded={expanded}
                                aria-label="show comments"
                            >
                            {this.state.commentCount}
                            <CommentIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent >
                        <CommunityCommentCard/>
                        <CommunityCommentCard/>
                        <CommunityCommentCard/>
                    </CardContent>
                </Collapse>
            </Card>
        );
    }

    share() {
        this.setState({shareCount: this.state.shareCount + 1})
    }

    like() {
        if (this.state.liked) {
            this.setState({liked: false, likeCount: this.state.likeCount - 1});
        } else {
            this.setState({liked: true, likeCount: this.state.likeCount + 1});
        }
    }
}

export default CommunityCard;
