import React, {Component} from 'react'
import './styles/patientcard.css';
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";

class CommunityChatCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.status || '',
            doctorName: props.doctorName || 'Sigmund Freud',
            doctorImage: props.doctorImage,
        };
    }

    render() {
        return (
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" src={this.state.doctorImage}>
                        {this.state.doctorName[0]}
                    </Avatar>
                }
                // action={
                //     <IconButton aria-label="settings">
                //         <MoreVertIcon/>
                //     </IconButton>
                // }
                title={this.state.doctorName}
                subheader={this.state.status}
            />
        );
    }
}

export default CommunityChatCard;
