import React, {Component} from 'react'
import './styles/patientcard.css';
import patientJSON from './../assets/data/patientList';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import thumb1 from "./../assets/patientThumb/1.jpg";
import './styles/patientcard.css'
class PatientCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Link to={'/patientInfo/'+this.state.patientId}>
            <Paper style={{height:80}}>
                <div className="patientcard">
                <Grid container component='div'className="patientcard" style={{padding: "30px"}} >
                    <Grid component='div' sm={2} >
                        {patient.time}
                    </Grid>
                    <Grid component='div' sm={2}>
                        <Avatar alt={patient.name} src={thumb1} component='div'/>
                    </Grid>
                    <Grid component='div' sm={8}>
                        {patient.name}
                    </Grid>
                </Grid>
                </div>
            </Paper>
            </Link>
        );
    }
}

export default PatientCard;
