import React, {Component} from 'react'
import './styles/patientcard.css';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import image2 from "../assets/data/doctor.jpg";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class CommunityEditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: props.liked || false,
            likeCount: props.likeCount || 0,
            shareCount: props.shareCount || 0,
            commentCount: props.commentCount || 0,
            doctorName: props.doctorName || 'Sigmund Freud',
            doctorImage: props.doctorImage || image2,
        };
    }

    render() {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" src={this.state.doctorImage}>
                            {this.state.doctorName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={this.state.doctorName}
                    subheader="Write Something..."
                />
                <CardContent>
                    <TextareaAutosize style={{width:'100%', height:100}} aria-label="empty textarea" placeholder="Write Something..." />
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container>
                        <Grid sm={4} onClick={()=>{this.like()}}>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUploadIcon />}
                                style={{width:'100%'}}
                            >
                                Upload
                            </Button>
                        </Grid>
                        <Grid sm={4}/>
                        <Grid sm={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>send</Icon>}
                                style={{width:'100%'}}
                            >
                                Post
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );
    }
}

export default CommunityEditCard;
