import React, {Component} from 'react'
import './styles/testscard.css';
import patientJSON from './../assets/data/patientList';
import Paper from "@material-ui/core/Paper";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";

export default class TestsCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Fab onClick={()=>{this.props.onClick()}} style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
            }} aria-label={`Add Prescription for ${patient.name}`}>
                <AddIcon/>
            </Fab>
        );
    }
}
